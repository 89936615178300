<template>
    <div class="offices-subscription">
        <div class="offices-subscription-cover cover container-fluid px-0 mt-5">
            <div class="container">
                <div class="row mx-2">
                    <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                        <h4 class="text-white mx-5 mt-5 mb-4">Абонаментно почистване</h4>
                        <p class="text-white mx-5 my-4">
                            Добрата етика по време на работа, качествената комуникация между служители и клиенти, както и добре обзаведеният и поддържан офис допринасят за просперитета на Вашия бизнес. Осигурете пълен комфорт за всички на работното място като се възползвате от услугата абонаментно почистване на офиси от фирма Cleaner.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-5 mb-3">
            <h3>Абонаментно почистване на офиси</h3>
            <p>
		        Предлагаме професионално абонаментно почистване на офиси с различна площ и степен на сложност. Услугата обхваща голям диапазон от дейности в областта на ежедневната хигиена.
	            Според Вашите нуждите извършваме абонаментното почистването в удобно за Вас време - всеки ден или в избрани дни от седмицата. Осигуряваме целодневен персонал и при необходимост резервен такъв.
            <br>
            <br>         
	            Предлагаме услугата на територията на цялата страна, за един или повече офиси, като гарантираме еднакви норми за поддържане на хигиена и чистота. 
	            Зареждаме и следим за наличието на хигиенни консумативи и почистващи препарати.
            <br>
            <br>          
	            Всяка от дейностите, включени в пакетната услуга, се определя индивидуално, въз основа на Вашите нужди, и се извършва периодично в дневни, седмични и месечни цикли.
            <br>
            <br>
            Качеството на услугата и организацията на процесите се ръководи от мениджър, отговорен за хигиената  съответния обект. Мениджърът следи за наличието на почистващи препарати, за навременната доставка на хигиенни консумативи и за цялостното качество на свършената работа. Грижи се за управлението на персонала, както и за специфичните нужди, които възникват по време на работния процес.
            </p>
            <br>
            <h3>Дейности по абонаментно почистване на офиси</h3>
            <div class="add-services flex flex-row">
                <ul class="two-cols">
                    <li>ИЗМИВАНЕ НА ТВЪРДИ ПОДОВИ НАСТИЛКИ</li>
                    <li>ПОЧИСТВАНЕ НА МЕКИ ПОДОВИ НАСТИЛКИ С ПРАХОСМУКАЧКА</li>
                    <li>ЗАБЪРСВАНЕ И ПОЛИРАНЕ НА ОФИС МЕБЕЛИ</li>
                    <li>АБОНАМЕНТНО ПОЧИСТВАНЕ И ДЕЗИНФЕКЦИЯ НА САНИТАРНИ ПОМЕЩЕНИЯ</li>
                    <li>ОБЕЗПРАШАВАНЕ НА ОФИС ТЕХНИКА</li>
                    <li>ПРОВЕТРЯВАНЕ НА РАБОТНИ ПОМЕЩЕНИЯ</li>
                    <li>ЗАБЪРСВАНЕ НА ПРАХ</li>
                    <li>ОТСТРАНЯВАНЕ НА ПАЯЖИНИ</li>
                    <li>ПОЧИСТВАНЕ НА ПЕРВАЗИ НА ПРОЗОРЦИ</li>
                    <li>ПОЧИСТВАНЕ НА КЛЮЧОВЕ ЗА ОСВЕТЛЕНИЕ</li>
                    <li>ПОЧИСТВАНЕ НА КОНТАКTИ И ДР.</li>
                    <li>ПОЧИСТВАНЕ НА СТЪКЛЕНИ ПОВЪРХНОСТИ</li>
                    <li>ЗАБЪРСВАНЕ НА ВРАТИ, ПРЕГРАДНИ СТЕНИ И ПАРАПЕТИ</li>
                    <li>ИЗМИВАНЕ НА ОСВЕТИТЕЛНИ ТЕЛА</li>
                    <li>ИЗМИВАНЕ НА ВХОДНИ И ПРЕГРАДНИ ВИТРИНИ</li>
                    <li>СЪБИРАНЕ И ИЗМИВАНЕ НА КУХНЕНСКА ПОСУДА</li>
                    <li>ГРИЖА ЗА ИНТЕРИОРНА РАСТИТЕЛНОСТ</li>
                    <li>ЗАРЕЖДАНЕ НА ХИГИЕННИ КОНСУМАТИВИ</li>
                    <li>ПОЧИСТВАНЕ НА КОШЧЕТА ЗА СМЕТ</li>
                    <li>ИЗХВЪРЛЯНЕ НА ОТПАДЪЦИ</li>
                    <li>ПОДМЯНА НА ЧУВАЛИ ЗА СМЕТ</li>
                    <li>ПОДДРЪЖКА НА ОБЩИ ЧАСТИ</li>
                </ul>
            </div>
            <div class="bagdes container my-5">
                <div class="row">
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex bump"
                        @click="serviceActive = true, service2Active = false, service3Active = false">
                            <span class="d-flex align-items-center">
                                <svg width="75px" height="75px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve">
                                    <path d="M464,220V102.232L352,64.896V220h-16V44H144v176h-16v-96h-16V68H80V20H64v48H32v56H16v96H0v224h144v16h192v-16h144V220
                                        H464z M48,84h48v40H48V84z M32,140h80v80H32V140z M144,428H16V236h128V428z M256,444h-32v-80h32V444z M320,444h-48v-96h-64v96h-48
                                        V60h160V444z M368,87.104l80,26.664V220h-16v-96h-16v96h-16v-96h-16v96h-16V87.104z M416,428h-32v-32h32V428z M464,428h-32v-48
                                        h-64v48h-32V236h128V428z"/>
                                    <rect x="32" y="252" width="96" height="16"/>
                                    <rect x="32" y="284" width="96" height="16"/>
                                    <rect x="32" y="316" width="96" height="16"/>
                                    <rect x="32" y="348" width="96" height="16"/>
                                    <path d="M184,92v48h48V92H184z M216,124h-16v-16h16V124z"/>
                                    <path d="M248,92v48h48V92H248z M280,124h-16v-16h16V124z"/>
                                    <path d="M184,156v48h48v-48H184z M216,188h-16v-16h16V188z"/>
                                    <path d="M248,156v48h48v-48H248z M280,188h-16v-16h16V188z"/>
                                    <path d="M184,220v48h48v-48H184z M216,252h-16v-16h16V252z"/>
                                    <path d="M248,220v48h48v-48H248z M280,252h-16v-16h16V252z"/>
                                    <path d="M184,284v48h48v-48H184z M216,316h-16v-16h16V316z"/>
	                            	<path d="M248,284v48h48v-48H248z M280,316h-16v-16h16V316z"/>
	                            	<rect x="352" y="252" width="16" height="16"/>
	                            	<rect x="384" y="252" width="32" height="16"/>
	                            	<rect x="432" y="252" width="16" height="16"/>
		                            <rect x="352" y="284" width="16" height="16"/>
		                            <rect x="384" y="284" width="32" height="16"/>
	                            	<rect x="432" y="284" width="16" height="16"/>
	                            	<rect x="352" y="316" width="16" height="16"/>
	                            	<rect x="384" y="316" width="32" height="16"/>
	                            	<rect x="432" y="316" width="16" height="16"/>
		                            <rect x="352" y="348" width="16" height="16"/>
		                            <rect x="384" y="348" width="32" height="16"/>
		                            <rect x="432" y="348" width="16" height="16"/>
                                </svg>
                            </span>
                            <span class="d-block pl-3">
                                <span class="d-block badges-title">Офис площи, Заседателни зали, Работни помещения</span>
                            </span>
                        </span>
                    </div>
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex bump"
                        @click="service2Active = true, serviceActive = false, service3Active = false">
                            <span class="d-flex align-items-center">
                                <svg width="75px" height="75px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 511.988 511.988" style="enable-background:new 0 0 511.988 511.988;" xml:space="preserve">
                                    <path d="M394.655,202.654H95.988c-17.643,0-32,14.357-32,32v21.333c0,76.117,42.048,145.152,109.717,180.16
                                        c5.227,2.688,11.669,0.661,14.379-4.587c2.688-5.227,0.661-11.669-4.587-14.379c-60.544-31.317-98.176-93.077-98.176-161.195
                                        v-21.333c0-5.888,4.779-10.667,10.667-10.667h298.667c5.888,0,10.667,4.779,10.667,10.667v21.333
                                        c0,68.117-37.632,129.877-98.197,161.173c-5.248,2.709-7.275,9.152-4.587,14.379c1.899,3.669,5.632,5.781,9.493,5.781
                                        c1.664,0,3.328-0.384,4.885-1.195c67.691-34.987,109.739-104,109.739-180.139v-21.333
                                        C426.655,217.011,412.297,202.654,394.655,202.654z"/>
                                    <path d="M489.865,422.558c-1.664-3.989-5.547-6.592-9.856-6.592H10.676c-4.309,0-8.192,2.603-9.856,6.592
                                        c-1.664,3.989-0.747,8.576,2.304,11.627l23.915,23.936c14.123,14.101,32.853,21.867,52.8,21.867h330.965
                                        c19.947,0,38.699-7.765,52.843-21.867l23.915-23.936C490.612,431.134,491.529,426.547,489.865,422.558z M448.543,443.038
                                        c-10.069,10.069-23.488,15.616-37.717,15.616H79.839c-14.229,0-27.648-5.547-37.717-15.616l-5.717-5.717H454.26L448.543,443.038z"
                                        />
                                    <path d="M491.359,253.022c-28.736-18.475-74.645,1.579-79.787,3.904c-5.355,2.453-7.744,8.768-5.291,14.144
                                        c2.453,5.376,8.747,7.744,14.144,5.291c10.283-4.672,43.264-15.744,59.392-5.355c7.275,4.672,10.837,13.739,10.837,27.648
                                        c0,42.709-86.144,68.523-119.403,74.88l-7.552,1.493c-5.781,1.131-9.536,6.741-8.405,12.523c1.003,5.077,5.461,8.597,10.453,8.597
                                        c0.683,0,1.365-0.064,2.069-0.213l7.488-1.472c5.589-1.067,136.683-26.773,136.683-95.808
                                        C511.988,277.193,505.034,261.854,491.359,253.022z"/>
                                    <path d="M328.372,113.31c17.344-21.675,17.344-55.616,0-77.291c-3.691-4.608-10.368-5.376-14.997-1.664
                                        c-4.608,3.691-5.355,10.389-1.664,14.997c10.987,13.717,10.987,36.907-0.021,50.667c-17.365,21.675-17.365,55.616,0,77.291
                                        c2.112,2.645,5.205,4.011,8.341,4.011c2.325,0,4.693-0.768,6.656-2.347c4.608-3.691,5.355-10.411,1.664-14.997
                                        C317.343,150.259,317.343,127.07,328.372,113.31z"/>
                                    <path d="M264.287,113.31c17.365-21.675,17.365-55.616,0-77.291c-3.669-4.608-10.389-5.355-14.997-1.664
                                        c-4.608,3.691-5.355,10.411-1.664,14.997c11.008,13.717,11.008,36.907-0.021,50.667c-17.344,21.675-17.344,55.616,0,77.291
                                        c2.112,2.645,5.205,4.011,8.341,4.011c2.325,0,4.672-0.768,6.656-2.347c4.608-3.691,5.355-10.389,1.664-14.997
                                        C253.279,150.259,253.279,127.07,264.287,113.31z"/>
                                    <path d="M200.372,113.331c17.344-21.675,17.344-55.616,0-77.291c-3.669-4.608-10.368-5.376-14.997-1.664
                                        c-4.587,3.669-5.333,10.389-1.664,14.976c10.987,13.717,10.987,36.907-0.021,50.667c-17.365,21.675-17.365,55.616,0,77.291
                                        c2.112,2.645,5.205,4.011,8.341,4.011c2.325,0,4.693-0.768,6.656-2.325c4.608-3.691,5.355-10.411,1.664-14.997
                                        C189.343,150.259,189.343,127.091,200.372,113.331z"/>
                                </svg>
                            </span>
                            <span class="d-block pl-3">
                                <span class="d-block badges-title">Кухненски, Сървърни и Почивни помещения</span>
                            </span>
                        </span>
                    </div>
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex bump"
                        @click="service3Active = true, serviceActive = false, service2Active = false">
                            <span class="d-flex align-items-center">
                                <svg width="75px" height="75px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	                            viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve">
                                    <path d="M416,336h-12.528c-8.786,0.008-17.45,2.053-25.312,5.976c-11.439,5.686-24.881,5.686-36.32,0
                                        c-4.563-2.239-9.406-3.854-14.4-4.8L343.032,216H360c4.418,0,8-3.582,8-8v-32c0-4.418-3.582-8-8-8h-32v-24
                                        c0-2.122-0.844-4.156-2.344-5.656L288,100.688V75.84l12.128-9.696l20.712,41.432l14.32-7.152L312.904,55.92L322.8,48H352
                                        c4.418,0,8-3.582,8-8V8c0-4.418-3.582-8-8-8h-80c-39.744,0.048-71.952,32.256-72,72c0,4.418,3.582,8,8,8h16v20.688l-21.656,21.656
                                        c-1.5,1.5-2.344,3.534-2.344,5.656v40h-16v-64c-0.012-0.991-0.21-1.97-0.584-2.888c-0.146-0.311-0.312-0.613-0.496-0.904
                                        c-0.192-0.419-0.417-0.823-0.672-1.208L152,61.2V8c0-4.418-3.582-8-8-8H96c-4.418,0-8,3.582-8,8v53.2L57.752,99
                                        c-0.255,0.385-0.48,0.789-0.672,1.208c-0.184,0.291-0.35,0.593-0.496,0.904C56.21,102.03,56.012,103.009,56,104v64H24
                                        c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h16.968l31.096,241.024c0.515,3.99,3.913,6.977,7.936,6.976h188.32
                                        c9.073,10.17,22.051,15.99,35.68,16h12.528c8.786-0.008,17.45-2.053,25.312-5.976c11.439-5.686,24.881-5.686,36.32,0
                                        c7.862,3.923,16.526,5.968,25.312,5.976H416c26.499-0.026,47.974-21.501,48-48v-48C463.974,357.501,442.499,336.026,416,336z
                                        M328,16h16v16h-16V16z M352,184v16H240v-16H352z M216.568,64c4.013-27.535,27.606-47.965,55.432-48h40v20.16L277.192,64H216.568z
                                        M272,80v16h-32V80H272z M216,131.312L235.312,112h41.376L312,147.312V168h-96V131.312z M208,184h16v56
                                        c-0.017,3.047,1.699,5.838,4.424,7.2l48,24c2.341,1.154,4.115,3.205,4.92,5.688c0.829,2.665,0.541,5.552-0.8,8
                                        c-2.302,4.619-7.912,6.497-12.531,4.194c-0.007-0.003-0.014-0.007-0.021-0.01l-32.48-16.24c-3.97-1.94-8.76-0.294-10.7,3.676
                                        c-0.531,1.088-0.809,2.282-0.812,3.492v80c0,8.837-7.163,16-16,16v-72h-16v72h-16v-72h-16v72h-16v-72h-16v72
                                        c-8.837,0-16-7.163-16-16V184H208z M104,16h32v40h-32V16z M99.84,72h40.32l19.2,24H80.64L99.84,72z M72,112h96v56H72V112z M32,200
                                        v-16h64v16H32z M256,384v48c0.014,5.454,0.961,10.865,2.8,16H87.032L57.096,216H96v144c0,17.673,14.327,32,32,32h80
                                        c17.673,0,32-14.327,32-32v-67.056l20.896,10.448c12.627,6.232,27.915,1.048,34.147-11.579c0.026-0.052,0.051-0.105,0.077-0.157
                                        c3.099-6.143,3.626-13.268,1.464-19.8c-2.132-6.535-6.802-11.937-12.96-14.992L240,235.056V216h86.904l-15.488,120H304
                                        C277.501,336.026,256.026,357.501,256,384z M448,432c0,17.673-14.327,32-32,32h-12.528c-6.301-0.009-12.514-1.476-18.152-4.288
                                        c-15.95-7.925-34.69-7.925-50.64,0c-5.638,2.812-11.851,4.279-18.152,4.288H304c-17.673,0-32-14.327-32-32v-12.32
                                        c8.773,7.927,20.176,12.317,32,12.32h12.528c8.786-0.008,17.45-2.053,25.312-5.976c11.439-5.686,24.881-5.686,36.32,0
                                        c7.862,3.923,16.526,5.968,25.312,5.976H416c11.824-0.003,23.227-4.393,32-12.32V432z M416,416h-12.528
                                        c-6.301-0.009-12.514-1.476-18.152-4.288c-15.95-7.925-34.69-7.925-50.64,0c-5.638,2.812-11.851,4.279-18.152,4.288H304
                                        c-17.673,0-32-14.327-32-32c0-17.673,14.327-32,32-32h12.528c6.301,0.009,12.514,1.476,18.152,4.288
                                        c15.951,7.92,34.689,7.92,50.64,0c5.638-2.812,11.851-4.279,18.152-4.288H416c17.673,0,32,14.327,32,32
                                        C448,401.673,433.673,416,416,416z"/>
                                    <rect x="408" y="368" width="16" height="16"/>
                                    <rect x="328" y="368" width="16" height="16"/>
                                    <rect x="296" y="376" width="16" height="16"/>
		                            <rect x="376" y="376" width="16" height="16"/>
                                </svg>
                            </span>
                            <span class="d-block pl-3">
                                <span class="d-block badges-title">Сервизни, Санитарни и Складови помещения</span>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex">
                            <span class="d-flex align-items-center">
                                <svg width="75px" height="75px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	                            viewBox="0 0 490.667 490.667" style="enable-background:new 0 0 490.667 490.667;" xml:space="preserve">
                                    <path d="M480,0H10.667C4.779,0,0,4.779,0,10.667V480c0,5.888,4.779,10.667,10.667,10.667H96c5.888,0,10.667-4.779,10.667-10.667
                                    v-10.667H384V480c0,5.888,4.779,10.667,10.667,10.667H480c5.888,0,10.667-4.779,10.667-10.667V10.667
                                    C490.667,4.779,485.888,0,480,0z M469.333,469.333h-64v-10.667c0-5.888-4.779-10.667-10.667-10.667H96
                                    c-5.888,0-10.667,4.779-10.667,10.667v10.667h-64v-448h448V469.333z"/>
                                    <path d="M394.667,106.667H96c-5.888,0-10.667,4.779-10.667,10.667v341.333c0,5.888,4.779,10.667,10.667,10.667h298.667
			                        c5.888,0,10.667-4.779,10.667-10.667V117.333C405.333,111.445,400.555,106.667,394.667,106.667z M384,448H106.667V128H384V448z"/>
                                    <path d="M245.333,106.667c-5.888,0-10.667,4.779-10.667,10.667v341.333c0,5.888,4.779,10.667,10.667,10.667
			                        S256,464.555,256,458.667V117.333C256,111.445,251.221,106.667,245.333,106.667z"/>
                                    <circle cx="437.333" cy="224" r="10.667"/>
                                    <circle cx="437.333" cy="266.667" r="10.667"/>
                                    <path d="M210.219,67.136l-21.333-21.333c-4.16-4.16-10.923-4.16-15.083,0l-21.333,21.333c-4.16,4.16-4.16,10.923,0,15.083
			                        c4.16,4.16,10.923,4.16,15.083,0l13.781-13.803l13.781,13.803c2.091,2.069,4.821,3.115,7.552,3.115
			                        c2.731,0,5.461-1.045,7.552-3.115C214.379,78.059,214.379,71.296,210.219,67.136z"/>
                                    <path d="M338.197,45.803c-4.16-4.16-10.923-4.16-15.083,0l-13.781,13.781l-13.803-13.781c-4.16-4.16-10.923-4.16-15.083,0
                                    c-4.16,4.16-4.16,10.923,0,15.083l21.333,21.333c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.531-3.115l21.333-21.333
                                    C342.357,56.725,342.357,49.963,338.197,45.803z"/>
                                </svg>
                            </span>
                            <span class="d-block pl-3">
                                <span class="d-block badges-title">Фоайета, асансьори и рецепция</span>
                            </span>
                        </span>
                    </div>
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex">
                            <span class="d-flex align-items-center">
                                <svg width="75px" height="75px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 511.998 511.998" style="enable-background:new 0 0 511.998 511.998;" xml:space="preserve">
                                    <path d="M501.998,30.598h-152c-5.523,0-10,4.477-10,10v40.003c0,5.523,4.477,10,10,10h20.001v74.794h-190
                                    c-5.522,0-10,4.477-10,10v40.002c0,5.523,4.478,10,10,10H200v74.796H10c-5.523,0-10,4.477-10,10v40.001c0,5.523,4.477,10,10,10
                                    h13.996V471.4c0,5.523,4.477,10,10,10h181.76c2.256,0,4.445-0.763,6.213-2.165l286.243-226.972
                                    c2.392-1.896,3.786-4.782,3.786-7.835V80.601V40.598C511.998,35.075,507.522,30.598,501.998,30.598z M189.999,205.397v-20.002
                                    h180v20.002H189.999z M20,340.194v-20.001h180v20.001H20z M491.998,109.879h-41.035c-5.523,0-10,4.477-10,10
                                    c0,5.523,4.477,10,10,10h41.035v109.715L212.271,461.4H43.996v-62.253h41.036c5.522,0,10-4.477,10-10s-4.478-10-10-10H43.996
                                    v-18.953H210c5.523,0,10-4.477,10-10v-40.001v-84.796h159.999c5.523,0,10-4.477,10-10v-40.002V90.601h101.999V109.879z
                                    M491.998,70.601h-132V50.598h132V70.601z"/>
                                    <path d="M114,379.144h-0.236c-5.524,0-10,4.477-10,10s4.476,10,10,10H114c5.522,0,10-4.477,10-10S119.522,379.144,114,379.144z"/>
                                    <path d="M421.998,129.88h0.235c5.522,0,10-4.477,10-10s-4.478-10-10-10h-0.235c-5.523,0-10,4.477-10,10S416.475,129.88,421.998,129.88z"/>
                                </svg>
                            </span>
                            <span class="d-block pl-3">
                                <span class="d-block badges-title">Коридори и стълбищни помещения</span>
                            </span>
                        </span>
                    </div>
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex">
                            <span class="d-flex align-items-center">
                                <svg id="Capa_1" enable-background="new 0 0 512 512" height="75" viewBox="0 0 512 512" width="75" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m130.496 200.953h157.465c7.715 0 13.991-6.276 13.991-13.991v-157.465c0-7.715-6.276-13.992-13.991-13.992h-157.465c-7.715 0-13.991 6.277-13.991 13.992v3.271h-26.869v-6.106c0-14.702-11.96-26.662-26.662-26.662s-26.662 11.96-26.662 26.662v275.67c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-275.67c0-6.431 5.231-11.662 11.662-11.662s11.662 5.231 11.662 11.662v13.606 36.142 63.638 36.142 278.233h-23.324v-117.876c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v117.876h-7.552c-15.859 0-28.76 12.889-28.76 28.732v16.923c0 6.527 5.31 11.837 11.837 11.837h102.274c6.542 0 11.865-5.31 11.865-11.837v-16.923c0-15.843-12.901-28.732-28.76-28.732h-7.581v-270.733h26.868v3.271c.001 7.716 6.278 13.992 13.993 13.992zm-19.519 282.202v13.76h-95.977v-13.76c0-7.572 6.173-13.732 13.76-13.732h15.052 38.324 15.081c7.587 0 13.76 6.16 13.76 13.732zm-21.341-399.244h26.868v48.638h-26.868zm41.868-53.406h155.448v155.448h-155.448v-9.763-36.142-63.637-36.142zm-15 17.264v21.142h-26.868v-21.142zm-26.868 120.921v-21.142h26.868v21.142z"/>
                                    <path d="m512 316.024c0-16.921-13.69-30.688-30.518-30.688h-28.673l-9.819-20.714c-5.928-12.504-10.408-21.035-18.367-25.499-7.348-4.121-15.734-4.093-30.993-4.048l-125.801.003-1.007-.003c-15.236-.049-23.626-.073-30.971 4.049-7.955 4.464-12.435 12.994-18.362 25.498l-9.819 20.714h-28.673c-16.828 0-30.518 13.766-30.518 30.688 0 16.781 13.466 30.452 30.102 30.677l-8.683 18.318c-4.839 10.181-5.175 11.586-5.128 21.483.004.869.008 68.222.008 68.222 0 5.668.008 14.396 8.079 17.615v20.908c0 10.341 8.413 18.753 18.753 18.753h34.781c10.325 0 18.725-8.413 18.725-18.753v-19.657h68.271c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-133.03c-.183 0-.351-.002-.505-.006-.074-1.254-.079-71.262-.083-72.152-.033-7.169-.033-7.169 3.68-14.981l11.727-24.739h270.126l11.731 24.747c3.687 7.756 3.687 7.756 3.649 14.843-.005.925-.01 71.028-.082 72.284-.146.004-133.084.006-133.084.006-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h67.817v19.656c0 10.341 8.412 18.753 18.753 18.753h34.781c10.341 0 18.754-8.413 18.754-18.753v-20.914c8.043-3.223 8.051-11.945 8.051-17.609 0 0 .005-67.444.01-68.346.051-9.725-.331-11.322-5.099-21.352l-8.687-18.325c16.637-.226 30.104-13.897 30.104-30.678zm-348.521 0c0-8.65 6.961-15.688 15.518-15.688h21.563l-14.872 31.375h-6.69c-8.558 0-15.519-7.037-15.519-15.687zm66.638 157.566v19.656c0 2.035-1.706 3.753-3.725 3.753h-34.781c-2.035 0-3.753-1.719-3.753-3.753v-19.656zm.926-202.543c5.262-11.1 8.422-16.75 12.148-18.841 3.904-2.19 11.304-2.171 23.585-2.13l125.891.003 1.009-.003c12.29-.041 19.702-.061 23.61 2.131 3.729 2.091 6.889 7.741 12.149 18.84l28.757 60.665h-255.905zm241.579 222.2c0 2.035-1.719 3.753-3.754 3.753h-34.781c-2.034 0-3.753-1.719-3.753-3.753v-19.657h42.288zm8.86-161.536h-6.69l-14.873-31.375h21.563c8.557 0 15.518 7.038 15.518 15.688s-6.961 15.687-15.518 15.687z"/>
                                    <path d="m292.213 399.436h76.054c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-76.054c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5z"/>
                                    <path d="m292.213 433.338h76.054c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-76.054c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5z"/>
                                    <path d="m456.129 401.829c0-17.375-14.122-31.509-31.481-31.509-17.374 0-31.509 14.135-31.509 31.509s14.135 31.51 31.509 31.51c17.359-.001 31.481-14.136 31.481-31.51zm-47.99 0c0-9.103 7.406-16.509 16.509-16.509 9.088 0 16.481 7.406 16.481 16.509 0 9.104-7.394 16.51-16.481 16.51-9.103-.001-16.509-7.407-16.509-16.51z"/>
                                    <path d="m204.35 401.829c0 17.375 14.123 31.51 31.481 31.51 17.375 0 31.509-14.135 31.509-31.51s-14.135-31.509-31.509-31.509c-17.358-.001-31.481 14.134-31.481 31.509zm47.991 0c0 9.104-7.406 16.51-16.509 16.51-9.088 0-16.481-7.406-16.481-16.51 0-9.103 7.394-16.509 16.481-16.509 9.103-.001 16.509 7.406 16.509 16.509z"/>
                                    <path d="m211.309 45.459h-30.779c-4.081 0-7.5 3.418-7.5 7.5v110.541c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-40.556c7.208-.04 17.942-.095 23.279-.095 21.673 0 39.306-17.358 39.306-38.695s-17.633-38.695-39.306-38.695zm0 62.39c-5.305 0-15.886.054-23.108.094-.041-7.404-.095-18.378-.095-23.789 0-4.554-.031-16.001-.054-23.695h23.256c13.175 0 24.306 10.851 24.306 23.695s-11.131 23.695-24.305 23.695z"/>
                                </svg>
                            </span>
                            <span class="d-block pl-3">
                                <span class="d-block badges-title">Паркинг, Прилежащи Производствени помещения</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="results">
                <p v-show="serviceActive">
                    Абонаментно почистване на ОФИС ПЛОЩИ, ЗАСЕДАТЕЛНИ ЗАЛИ, РАБОТНИ ПОМЕЩЕНИЯ е професионална услуга, предлагана както за корпоративни клиенти, така и за малки и средни фирми.
                    <br><br>
                    Периодичността зависи от вида на дейността и необходимостта от поддържане на хигиена на високо ниво. Според желанието на клиента се сключва договор, в който се описва подробно обхвата на услугата.
                    <br><br>
                    Периодът на абонамента зависи от конкретната ситуация, както и от конкретните действия: месечна профилактика, ежеседмична или ежедневна дезинфекция, ежедневно почистване на подове и хигиенни помещения.
                    <br><br>
                    Абонаментното почистване на офиси се извършва в подходящ момент от деня или седмицата. Изборът му зависи от работното време на фирмата или на конкретния екип. При необходимост осигуряваме целодневно присъствие на наш персонал.
                    <br><br>
                    Заседателните зали изискват допълнително внимание. Те са мястото, на което екипът на фирмата се събира на работни срещи и обсъждания. Там се провеждат и срещите с клиенти и доставчици, с партньори. Тези помещения в много случаи са лицето на фирмата.
                    <br><br>
                    Почистването на заседателните зали се извършва заедно с останалите дейности от абонамента, и при необходимост преди или след използването им.
                    <br><br>            
                    Работните помещения се почистват обикновено извън работното време, освен при друга договорка. 
                    <br><br>
                    За повече информация се свържете са нас.
                </p>
                <p v-show="service2Active">
                    На пръв поглед предназначението на кухненски, сървърни и почивни помещения са с абсолютно различно предназначение. Общото между тях е изискването на специализирана грижа и професионално почистване.
                    <br><br>
                    Сървърните помещения са предвидени за скъпоструваща компютърна техника. Обикновено тя е подредена на специални рафтове – ракове. Помещенията са окабелени, снабдени с охлаждане, което да поддържа добра среда за нормално функциониране на техниката. Поддържане на хигиена в подобни помещения на офиса изискват специална грижа: почистване на охлаждания, отстраняване на прах, следене изрядността и добрата аранжировка на кабелите.
                </p>
                <p v-show="service3Active">
                    Сервизните, санитарни и складови помещения в един офис изискват специализирана грижа. Те са сред онези работни части, от чиято хигиена зависи качеството на работа на екипа на фирмата.
                </p>
            </div>
        </div>
        <EmailContact />
    </div>
</template>
<script>
    import EmailContact from '../../contacts/EmailContact'

    export default {
        name: "OfficesSubscription",
        data() {
            return {
                serviceActive: false,
                service2Active: false,
                service3Active: false,
            }
        },
        components: {
            EmailContact,
        }
    }
</script>